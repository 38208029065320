import * as React from "react";

const NotFoundPage = ({ data, location }) => {
  return (
    <div className="container">
      <main>
        <h1>404: Not Found</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </main>
      <footer>
        © {new Date().getFullYear()} Graphnode. Octopod Delivery Service, a game
        being developed by Graphnode
      </footer>

      <img className="background" src="/images/background.png" />
    </div>
  );
};

export default NotFoundPage;
